if(window.location.pathname === '/') {
  new TypeIt(".bash-window span", {
    strings: [
      'curl -v \\',
      '&nbsp;&nbsp;&nbsp;-H "Content-Type: application/json" \\',
      '&nbsp;&nbsp;&nbsp;-H "X-Master-Key: <span class="highlight-code">&lt;YOUR_API_KEY&gt;</span>" \\',
      '&nbsp;&nbsp;&nbsp;--request POST \\',
      '&nbsp;&nbsp;&nbsp;--data \'{"sample": "Hello World"}\' \\',
      '&nbsp;&nbsp;&nbsp;https://api.jsonbin.io/v3/b'
    ],
    speed: 100,
    breakLines: true,
    cursorChar: '▐',
    waitUntilVisible: true
  }).go();
}