class App {
  constructor() {
    this.env();
    this.toggleHamburger();
    this.closeMenuOnBodyClick();
    this.preventMenuClose();
    this.supportNavigation();
    this.copyText();
    this.mobileNavigation();
    this.headerShadow();
    this.quickStoreEditor();
    this.createAccount();
    this.appEnv();
    this.resendVerificationForm();
    this.resendVerificationEmail();
    this.forgotPasswordForm();
    this.resetPassword();
  }

  appEnv() {
    window.appEnv =
      window.location.href.indexOf("localhost") > 0
        ? "development"
        : "production";
  }

  env() {
    window.basePath =
      window.location.href.indexOf("localhost") > 0
        ? "http://localhost:8082"
        : "https://api.jsonbin.io";
  }

  resetPassword() {
    $("#change-password-form").on("submit", function(e) {
      e.preventDefault();

      $('#change-password-btn').attr({
        disabled: true,
        value: 'Updating'
      });

      var formData = new FormData(e.target);
      var parsedFormData = Object.fromEntries(formData);

      $.ajax({
        url: "/change-password",
        type: "put",
        contentType: "application/json",
        dataType: "json",
        beforeSend: function () {
          $('.change-password-errors').text("").addClass("hide");
        },
        data: JSON.stringify(parsedFormData),
        success: function (data) {
          $('#change-password-btn').attr({
            disabled: false,
            value: 'Change Password'
          });
          $('.change-password-success').text("Password Updated Successfully").removeClass("hide");
          $('#change-password-form').trigger('reset');
          setTimeout(function () {
            $('.change-password-success').addClass("hide");
          }, 2000);
        },
        error: function (err) {
          $('#change-password-btn').attr({
            disabled: false,
            value: 'Change Password'
          });

          $('#change-password-form').trigger('reset');

          $('.change-password-errors')
            .text(JSON.parse(err.responseText).message)
            .removeClass("hide");
  
          setTimeout(function () {
            $('.change-password-errors').addClass("hide");
          }, 2000);
        },
      });
    });
  }

  quickStoreCreateFormHandling() {
    document.getElementById("createBinForm").addEventListener("submit", (e) => {
      e.preventDefault();
      const createBinSubmitButton = document.querySelector("#create_bin");

      createBinSubmitButton.disabled = true;
      createBinSubmitButton.value = "Saving";

      const formData = new FormData(e.target);
      let parsedFormData = Object.fromEntries(formData);
      parsedFormData.formJSONPayload = ace
        .edit("editor")
        .getValue()
        .replaceAll("\n", "");

      const formErrorElm = document.querySelector("#form-error");

      grecaptcha
        .execute(window.appEnv === 'development' ? '6Le1c30fAAAAAOkTRvxbK9Sf_D0guS8vAfAKzRQX' : '6LdpWX0fAAAAAJp7v3aIx09S10YQoZOTQ6pjCrFr', {
          action: "submit",
        })
        .then((token) => {
          // let recaptchaData = Object.assign({}, parsedFormData, { token });

          fetch(`${window.basePath}/v3/qs`, {
            method: "POST",
            body: parsedFormData.formJSONPayload,
            headers: {
              "Content-Type": "application/json",
              "X-Recaptcha-Token": token,
              "X-Bin-Name": parsedFormData.json_file_name,
              "X-Bin-Expires": parsedFormData.expires_in,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              createBinSubmitButton.value = "Create Bin";
              createBinSubmitButton.disabled = false;

              if (!data.success) {
                formErrorElm.classList.add("show");
                formErrorElm.textContent = data.message;

                setTimeout(() => {
                  formErrorElm.classList.remove("show");
                }, 2000);
              } else {
                document.querySelector("#createBinForm").reset();
                ace.edit("editor").setValue("");
                window.location = `/quick-store/${data.id}`;
              }
            })
            .catch((error) => {
              formErrorElm.classList.add("show");
              formErrorElm.textContent = "Something went wrong";
              createBinSubmitButton.disabled = false;
              createBinSubmitButton.value = "Create Bin";
              setTimeout(() => {
                formErrorElm.classList.remove("show");
              }, 2000);
            });
        });
    });
  }

  resendVerificationEmail() {
    $("#resend-verification").on("submit", function(e) {
      e.preventDefault();

      $('#resend-verify-email-account-btn').attr({
        disabled: true,
        value: 'Sending'
      });

      var formData = new FormData(e.target);
      var parsedFormData = Object.fromEntries(formData);

      $.ajax({
        url: "/verify-email",
        type: "post",
        contentType: "application/json",
        dataType: "json",
        beforeSend: function () {
          $('.resend-verification-email-errors').text("").addClass("hide");
        },
        data: JSON.stringify(parsedFormData),
        success: function (data) {
          $('#resend-verify-email-account-btn').attr({
            disabled: false,
            value: 'Verify'
          });
          window.location = '/verify-email';
        },
        error: function (err) {
          $('#resend-verify-email-account-btn').attr({
            disabled: false,
            value: 'Verify'
          });

          $('.resend-verification-email-errors')
            .text(JSON.parse(err.responseText).message)
            .removeClass("hide");
  
          setTimeout(function () {
            $('.resend-verification-email-errors').addClass("hide");
          }, 2000);
        },
      });
    });
  }

  resendVerificationForm() {
    $('#resend-verification-cta').on('click', (e) => {
      e.preventDefault();

      $(e.currentTarget).attr('data-disabled', true);
      $('#resend-verification').removeClass('hide');
    });

    $('#close-resend-verification').on('click', (e) => {
      e.preventDefault();
      $('#resend-verification').trigger('reset').addClass('hide');
      $('#resend-verification-cta').attr('data-disabled', null);
    });
  }

  forgotPasswordForm() {
    $("#forgot-password-form").on("submit", function(e) {
      e.preventDefault();

      $('#forgot-password-btn').attr({
        disabled: true,
        value: 'Sending'
      });

      var formData = new FormData(e.target);
      var parsedFormData = Object.fromEntries(formData);

      $.ajax({
        url: "/forgot-password",
        type: "post",
        contentType: "application/json",
        dataType: "json",
        beforeSend: function () {
          $('.forgot-password-errors').text("").addClass("hide");
        },
        data: JSON.stringify(parsedFormData),
        success: function (data) {
          $('#forgot-password-btn').attr({
            disabled: false,
            value: 'Send'
          });
          $('.forgot-password-success').text("Email Sent").removeClass("hide");
          $('#forgot-password-form').trigger('reset');
          setTimeout(function () {
            $('.forgot-password-success').addClass("hide");
          }, 2000);
        },
        error: function (err) {
          $('#forgot-password-btn').attr({
            disabled: false,
            value: 'Send'
          });

          $('#forgot-password-form').trigger('reset');

          $('.forgot-password-errors')
            .text(JSON.parse(err.responseText).message)
            .removeClass("hide");
  
          setTimeout(function () {
            $('.forgot-password-errors').addClass("hide");
          }, 2000);
        },
      });
    });
  }

  quickStoreEditor() {
    if (!document.getElementById("editor")) {
      return false;
    }

    let editor = ace.edit("editor", {
      useWorker: false,
      tabSize: 2,
      useSoftTabs: true,
      showPrintMargin: false,
      theme: "ace/theme/dreamweaver",
      mode: "ace/mode/json",
      placeholder: `// add your JSON here, for example:

{ 
  "name": "John Doe", 
  "age": "31", 
  "jobTitle": "Engineering Manager", 
  "likes": ["beach", "food", "gaming"]
}`,
    });
    editor.focus();
  }

  toggleHamburger() {
    let mobileNav = document.querySelector(".mobile-nav");
    mobileNav.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      mobileNav.classList.toggle("is-active");
      document.querySelector(".hamburger-menu").classList.toggle("animate");
    });
  }

  closeMenuOnBodyClick() {
    document.body.addEventListener("click", () => {
      let mobileNav = document.querySelector(".mobile-nav");

      if ([...mobileNav.classList].includes("is-active")) {
        mobileNav.classList.remove("is-active");
        document.querySelector(".hamburger-menu").classList.toggle("animate");
      }
    });
  }

  preventMenuClose() {
    let mainMenu = document.querySelector(".main-menu");
    mainMenu.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  }

  supportNavigation() {
    let getSupportMobileNavigation = document.getElementById(
      "support-mobile-navigation"
    );
    if (getSupportMobileNavigation) {
      getSupportMobileNavigation.addEventListener("change", () => {
        let getPath =
          getSupportMobileNavigation.options[
            getSupportMobileNavigation.selectedIndex
          ].value;
        if (getPath === "") {
          return;
        } else {
          window.location.href = getPath;
        }
      });
    }
  }

  headerShadow() {
    let scrollBtn = document.getElementById("scroll-btn");
    let header = document.querySelector("header");

    if (window.scrollY > 100) {
      header.classList.add("header-shadow");
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        header.classList.add("header-shadow");

        if (scrollBtn) {
          scrollBtn.classList.add("opaque-it");
        }
      } else {
        header.classList.remove("header-shadow");

        if (scrollBtn) {
          scrollBtn.classList.remove("opaque-it");
        }
      }
    });
  }

  documentationActiveMenu() {
    const sections = document.querySelectorAll(
      ".documentation-content section"
    );
    const menuLinks = document.querySelectorAll("aside ul:first-of-type a");

    const makeActive = (link) =>
      menuLinks[link].classList.add("active-content-menu-item");
    const removeActive = (link) =>
      menuLinks[link].classList.remove("active-content-menu-item");
    const removeAllActive = () =>
      [...Array(sections.length).keys()].forEach((link) => removeActive(link));

    const sectionMargin = 120;

    let currentActive = 0;

    window.addEventListener("scroll", () => {
      const current =
        sections.length -
        [...sections]
          .reverse()
          .findIndex(
            (section) => window.scrollY >= section.offsetTop - sectionMargin
          ) -
        1;

      if (current !== currentActive) {
        removeAllActive();
        currentActive = current;
        makeActive(current);
      }
    });
  }

  copyText() {
    if (typeof ClipboardJS !== "undefined") {
      let clipboard = new ClipboardJS(".copy-code");

      clipboard.on("success", (event) => {
        event.clearSelection();
        event.trigger.textContent = "Copied";
        setTimeout(() => {
          event.trigger.textContent = "Copy";
        }, 2000);
      });
    }
  }

  mobileNavigation() {
    let getDocMobileNavigation = document.getElementById(
      "doc-mobile-navigation"
    );
    if (getDocMobileNavigation) {
      getDocMobileNavigation.addEventListener("change", () => {
        let getPath =
          getDocMobileNavigation.options[getDocMobileNavigation.selectedIndex]
            .value;

        window.location.hash = getPath === "" ? "root" : getPath;
      });
    }
  }

  createAccount() {
    $("#create-account-form").on("submit", function (e) {
      e.preventDefault();

      $('#create-account-btn').attr({
        disabled: true,
        value: 'Creating Account'
      });

      var formData = new FormData(e.target);
      var parsedFormData = Object.fromEntries(formData);

      grecaptcha
        .execute(window.appEnv === 'development' ? '6Le1c30fAAAAAOkTRvxbK9Sf_D0guS8vAfAKzRQX' : '6LdpWX0fAAAAAJp7v3aIx09S10YQoZOTQ6pjCrFr', {
          action: "create_account",
        })
        .then((token) => {
          $.ajax({
            url: "/create-account",
            type: "post",
            contentType: "application/json",
            dataType: "json",
            headers: {
              "X-Recaptcha-Token": token
            },
            beforeSend: function () {
              $('.create-account-errors').text("").addClass("hide");
            },
            data: JSON.stringify(parsedFormData),
            success: function (data) {
              $('#create-account-btn').attr({
                disabled: false,
                value: 'Create Account'
              });
              window.location = '/verify-email';
            },
            error: function (err) {
              $('#create-account-btn').attr({
                disabled: false,
                value: 'Create Account'
              });

              $('.create-account-errors')
                .text(JSON.parse(err.responseText).message)
                .removeClass("hide");
      
              setTimeout(function () {
                $('.create-account-errors').addClass("hide");
              }, 2000);
            },
          });
        });
      });
  }  
}

const AppInstance = new App();

function contactForm() {
  $("#contact-form").on("submit", function (e) {
    e.preventDefault();

    var err;
    var form = $("#contact-form");

    form.find(":input").each(function () {
      var elm = $(this);
      if (elm.attr("type") != "submit" && elm.val().trim() == "") {
        err = true;
        $('.note[data-note-type="error"]')
          .text(`${elm.attr("name").toUpperCase()} cannot be blank`)
          .removeClass("hide");
      }
    });

    //fix this later
    setTimeout(function () {
      $('.note[data-note-type="error"]').addClass("hide");
    }, 2000);

    if (err) {
      err = false;
      return false;
    }

    var formData = new FormData(e.target);
    var parsedFormData = Object.fromEntries(formData);

    $.ajax({
      url: "/contact",
      type: "post",
      contentType: "application/json",
      dataType: "json",
      beforeSend: function () {
        $('.note[data-note-type="error"]').text("").addClass("hide");
      },
      data: JSON.stringify(parsedFormData),
      success: function (data) {
        $('.note[data-note-type="success"]')
          .text("Thanks for contacting us. We will get back to you soon.")
          .removeClass("hide");

        form.find(":input").each(function () {
          if ($(this).attr("type") != "submit") {
            $(this).val("");
          }
        });

        setTimeout(function () {
          $('.note[data-note-type="success"]').addClass("hide");
        }, 5000);
      },
      error: function (err) {
        err = JSON.parse(err.responseText);
        $('.note[data-note-type="error"]')
          .text(err.message)
          .removeClass("hide");

        setTimeout(function () {
          $('.note[data-note-type="error"]').addClass("hide");
        }, 2000);
      },
    });
  });
}

contactForm();

(() => {
  var requestPriceMapping = {
    '100000': {
      amount: {
        "INR": 800.00,
        "USD": 10.00
      },
      sku: 101
    },
    '500000': {
      amount: {
        "INR": 1200.00,
        "USD": 15.00
      },
      sku: 102
    },
    '1000000': {
      amount: {
        "INR": 2000.00,
        "USD": 25.00
      },
      sku: 103
    },
    '2000000': {
      amount: {
        "INR": 3200.00,
        "USD": 40.00
      },
      sku: 104
    },
  };

  let additionalRequests = document.getElementById(
    "additional-requests-dropdown"
  );
  if (additionalRequests) {
    let getUserCountry = SystemInfo.geoDetails.country;
    additionalRequests.addEventListener("change", () => {
      let val = additionalRequests.options[additionalRequests.selectedIndex];
      let req = val.getAttribute("data-request-count");
      let price =
        (getUserCountry == "IN" ? "₹" : "$") +
        (getUserCountry == "IN"
          ? requestPriceMapping[req].amount.INR
          : requestPriceMapping[req].amount.USD);
      document.getElementById("additional-request-price").textContent = price;
      document
        .querySelector("#pricing-requests-form [name=sku]")
        .setAttribute("value", val.value);
    });
  }
})();

(() => {
  $("[data-tab-trigger]").on("click", function (e) {
    let loadCodeFor = $(this).attr("data-tab-trigger");
    let documentationContainer = $(this).parents(
      ".documentation-tabs-container"
    );
    $(this).siblings().removeClass("active-tab");
    $(this).addClass("active-tab");
    documentationContainer
      .find(".documentation-tab-content [data-tab-content]")
      .addClass("hide");
    documentationContainer
      .find(`.documentation-tab-content [data-tab-content=${loadCodeFor}]`)
      .removeClass("hide");
  });
})();

(() => {
  $(".mobile-tabs").on("change", function (e) {
    let loadCodeFor = $(this).val();
    let documentationContainer = $(this).parents(
      ".documentation-tabs-container"
    );
    documentationContainer
      .find(".documentation-tab-content [data-tab-content]")
      .addClass("hide");
    documentationContainer
      .find(`.documentation-tab-content [data-tab-content=${loadCodeFor}]`)
      .removeClass("hide");
  });
})();

var commentSlider = {
  config: {
    container: $(".testimonial-slide"),
  },

  init: function (config) {
    if (config && typeof config == "object") {
      $.extend(commentSlider.config, config);
    }

    //caching dom elements
    //wrapper
    commentSlider.$container = commentSlider.config.container;

    //all paragraph tags
    commentSlider.$paragraphs = commentSlider.$container.find("p");

    //all li tags
    commentSlider.$dots = commentSlider.$container.find(
      "ul.testimonial-dots > li"
    );

    //first li within ul.dots-wrap
    commentSlider.$firstDot = commentSlider.$container.find(
      "ul.testimonial-dots > li:first-child"
    );

    //first p tag within module wrapper
    commentSlider.$firstParagraph =
      commentSlider.$container.find("p:first-child");

    //setting first dot with .active class
    commentSlider.$firstDot.addClass("testimonial-active");

    //setting first paragraph tag with .active class
    commentSlider.$firstParagraph.addClass("active-text");

    //initializing functions and defining their parameters
    commentSlider.currentItem(commentSlider.$paragraphs, commentSlider.$dots);
    commentSlider.setActiveDot(commentSlider.$dots);
  },

  //grabs current numerical class of dot clicked
  dotNumber: function ($dot) {
    var dotClassArray = [];
    var dotClassList = dotClassArray.push($dot.attr("class"));
    var splitArray = dotClassArray.toString().split(" ");

    for (var i = 0; i < splitArray.length; i++) {
      if (splitArray[i] === "dot") {
        splitArray.splice(i, 1);
        var dotClickedNumber = splitArray[i];
        commentSlider.paragraphNumber(
          dotClickedNumber,
          commentSlider.$paragraphs
        );
      }
    }
  }, //end dotNumber

  paragraphNumber: function (dotClickedNumber, $paragraphs) {
    $paragraphs.each(function () {
      var $paragraph = $(this);
      var paragraphClass = $paragraph.attr("class");

      if (paragraphClass === dotClickedNumber) {
        $paragraph.addClass("active-text");
        $paragraph.siblings().removeClass("active-text").addClass("slide-left");
        setTimeout(function () {
          $paragraph.siblings().removeClass("slide-left");
        }, 400);
      }
    });
  }, //end paragraphNumber

  //currentItem function gives every paragraph and dot a numerical class
  //based on their array position
  currentItem: function ($paragraphs, $dots) {
    $paragraphs.each(function (i) {
      var $paragraph = $(this);
      $paragraph.addClass([] + i);
    });

    $dots.each(function (i) {
      var $dot = $(this);
      $dot.addClass([] + i);
    });
  }, //end currentItem

  //setActiveDot adds class active to whichever dot is clicked
  setActiveDot: function ($dots) {
    $dots.each(function () {
      var $dot = $(this);
      $dot.on("click", function () {
        if ($dot.hasClass("testimonial-active")) {
          return false;
        } else {
          $dot.addClass("testimonial-active");
          $dot.siblings().removeClass("testimonial-active");
        }
        commentSlider.dotNumber($dot);
      });
    });
  }, //end setActiveDot
};

//initializes the entire thing by calling the init function
$(document).ready(commentSlider.init);
